<template>
  <div class="inputBox">
    <h1 class="welcome">密码修改</h1>

    <el-input
      class="account"
      v-model="mobile"
      placeholder="请输入手机号或工作邮箱"
      maxlength="11"
    ></el-input>

    <Drag @verify="dragmove" />

    <el-input
      v-model="code"
      type="text"
      placeholder="请输入验证码"
      maxlength="6"
    >
      <Svcode slot="suffix" />
    </el-input>

    <el-input
      v-if="isShowpwd"
      v-model="pwd"
      type="text"
      placeholder="输入新密码"
    >
      <i
        slot="suffix"
        class="iconfont icon-yanjing1"
        @click="isShowpwd = false"
      ></i>
    </el-input>
    <el-input v-else v-model="pwd" type="password" placeholder="输入新密码">
      <i
        slot="suffix"
        class="iconfont icon-yanjing"
        @click="isShowpwd = true"
      ></i>
    </el-input>

    <el-input
      v-if="isShowpwd"
      v-model="pwd2"
      type="text"
      placeholder="再次输入新密码"
    >
      <i
        slot="suffix"
        class="iconfont icon-yanjing1"
        @click="isShowpwd = false"
      ></i>
    </el-input>
    <el-input
      v-else
      v-model="pwd2"
      type="password"
      placeholder="再次输入新密码"
    >
      <i
        slot="suffix"
        class="iconfont icon-yanjing"
        @click="isShowpwd = true"
      ></i>
    </el-input>

    <el-button class="loginBtn" @click="submit">提交</el-button>
  </div>
</template>
<style lang="less" scoped>
.el-input__suffix .el-input__suffix-inner div {
  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 30px;
    background-color: #777;
    vertical-align: middle;
    margin-right: 22px;
  }
}
</style>
<script>
import Drag from "../../components/Drag";
import Svcode from "../../components/Svcode";
export default {
  data() {
    return {
      mobile: "",
      success: false,
      isShowpwd: false,
      code: "",
      pwd: "",
      pwd2: ""
    };
  },
  methods: {
    dragmove(val) {
      //   console.log(val);
      this.success = val;
    },
    submit() {
      //   let vcode = this.vcode;
      //   console.log(vcode);
      if (!this.success) {
         setmas("请滑动验证","warning",this)
        
        // this.$message({
        //   message: "请滑动验证",
        //   type: "warning"
        // });
      } else if (!this.code) {
         setmas("请输入验证码","warning",this)

        // this.$message({
        //   message: "请输入验证码",
        //   type: "warning"
        // });
      } else {
        this.$common.postInterface(
          "/api/v1.login/find_pass",
          {
            mobile: this.mobile,
            code: this.code,
            password: this.pwd,
            password2: this.pwd2
          },
          this,
          res => {
            console.log(res);
         setmas("密码已修改","success",this)

            // this.$message.success("密码已修改");
            this.$router.replace("/login/password");
          }
        );
        // setTimeout(() => {
        //   this.$router.replace("/login/password");
        // }, 2500);
      }
    }
  },
  components: {
    Drag,
    Svcode
  }
};
// 提醒弹出框
function setmas(msg,type,vm){
       vm.$message({
                  message:msg,
                  type:type,
                  customClass:"popup"
                  
    });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;

  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>