<template>
  <div class="drag">
    <div class="text">拖动滑块到最右侧进行验证</div>
    <div class="bg"></div>
    <div class="btn" v-drag="{ set: setVal }">
      <i class="iconfont icon-shuangjiantoushang"></i>
      <i class="iconfont icon-shuangjiantoushang"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    setVal(val) {
      //   this.verification = val;
      //   console.log(this.verification);
      this.$emit("verify", val);
    }
  },
  directives: {
    //自定义指令
    drag(el, binding) {
      el.ontouchstart = el.onmousedown = function(e) {
        var oBg = el.previousElementSibling, //绿色背景
          oText = oBg.previousElementSibling; //验证成功时需要改变文本的元素
        var disX = e.clientX || e.targetTouches[0].pageX; //获取鼠标点击的位置
        var distance = el.parentNode.offsetWidth - el.offsetWidth; //获取滑块滑动距离
        //清除动画
        el.style.transition = "";
        oBg.style.transition = "";

        document.ontouchmove = document.onmousemove = function(e) {
          //计算出元素移动后的位置
          var offsetX = (e.clientX || e.targetTouches[0].pageX) - disX;
          if (offsetX > distance) {
            //如果移动的距离已经大于本应该移动的距离 那么就将它设置为验证成功时的距离
            offsetX = distance;
          } else if (offsetX < 0) {
            //同样 如果移动的距离小于0时 将它设置为0 保证不会超出范围
            offsetX = 0;
          }
          el.style.left = offsetX + "px";
          oBg.style.width = offsetX + 40 + "px";
        };

        document.ontouchend = document.onmouseup = function() {
          if (el.offsetLeft == distance) {
            el.ontouchstart = el.onmousedown = null;
            oText.innerHTML = "验证通过";
            oText.style.color = "#FFF";
            binding.value.set(true);
          } else {
            el.style.transition = "left 0.5s ";
            oBg.style.transition = "width 0.5s ";
            el.style.left = 0;
            oBg.style.width = "40px";
          }
          document.ontouchmove = document.onmousemove = null;
        };
      };
    }
  }
};
</script>

<style lang="less" scoped>
.drag {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 24px;
  user-select: none;

  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 14px;
    color: #999;
  }

  .bg {
    height: 100%;
    width: 40px;
    border-radius: 6px;
    background-color: rgb(39, 233, 21);
  }

  .btn {
    position: absolute;
    top: 0;
    width: 40px;
    height: 100%;
    padding-left: 5px;
    background-color: #585858;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    cursor: move;
    i {
      display: inline-block;
      transform: translateX(-3px) rotate(90deg);
      margin: 0 3px;
      font-size: 12px;
    }
  }
}
</style>
