<template>
  <el-button type="text" @click="getCode" :disabled="send_vcode">{{
    vcodeText
  }}</el-button>
</template>

<style lang="less" scoped>
@deep: ~">>>";
// @{deep}.el-input {
//   .el-input__suffix {
//     right: 20px;
//     font-size: 18px;
//     line-height: 58px;
//     cursor: pointer;
//   }
//   .el-input__suffix-inner {
//     display: block;
//     .el-button {
//       font-size: 18px;
//       color: #aaa;
//       border-left: 1px solid #aaa;
//       border-radius: 0;
//       padding: 8px 0 8px 22px;
//     }
//   }
// }
</style>

<script>
export default {
  props: { mobile: [String, Number] },
  data() {
    return {
      send_vcode: false,
      vcodeText: "获取验证码"
    };
  },
  methods: {
    getCode() {
      this.send_vcode = true;
      let lowTime = 60;
      this.vcodeText = lowTime + "s后重新发送";
      lowTime--;
      let time = setInterval(() => {
        if (lowTime <= 0) {
          this.vcodeText = "重新获取验证码";
          this.send_vcode = false;
          clearInterval(time);
        } else {
          this.vcodeText = lowTime + "s后重新发送";
          lowTime--;
        }
      }, 1000);
      // console.log(this.$Common.api_url);
      this.$common.postInterface(
        "/api/v1.login/send_sms",
        { mobile: this.mobile },
        this,
        function(res) {
          if (res.status == "200") {
            this.$message.success("发送成功");
          } else {
            this.$message.success("发送失败");
          }
          console.log(res);
        }
      );
    }
  }
};
</script>
